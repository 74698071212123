import {Location} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';

import {AuthenticationService} from './services/authentication.service';
import {BreadCrumbService} from './services/bread-crumb.service';
import {ProjectService} from './services/project.service';
import {TaskService} from './services/task.service';
import {BreadCrumb} from './types/breadcrumb.class';
import {Project} from './types/project.class';
import {Task} from './types/task.class';
import {User} from './types/user.class';
import {TrelloService} from './services/trello.service';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FileShare} from './types/file-share.class';
import {ShareFileComponent} from './modules/shared/components/share-file/share-file.component';
import {UserService} from './services/user.service';
import {GenRoleDefinition} from './generated/serverModels/GenRoleDefinition';
import {ConfirmPromptComponent, ConfirmPromptConfig} from './components/confirm-prompt/confirm-prompt.component';
import {ManagedSettingsService} from './services/managed-settings.service';

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit {
  public subscription: Subscription;
  public user: User;
  public crumbs: BreadCrumb[] = [];
  public tasksNeedingReview: Task[] = [];
  public ownedProject: Project;
  sessionIsValid: Observable<boolean>;
  roles: GenRoleDefinition[] = [];

  trelloEnabled = false;

  hasMapAccess = false;

  constructor(private router: Router,
              public location: Location,
              public http: HttpClient,
              public breadcrumbService: BreadCrumbService,
              public authenticationService: AuthenticationService,
              public taskService: TaskService,
              public projectService: ProjectService,
              public dialog: MatDialog,
              private trelloService: TrelloService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private popup: MatSnackBar,
              private settings: ManagedSettingsService,
              private userService: UserService) {
    this.matIconRegistry.addSvgIcon(
      'trello-logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/trello-logo-blue.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'trello-mark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/trello-mark-blue.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'complete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/complete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'download',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/download.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'inprogress',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/inprogress.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'inqueue',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/inqueue.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'newuser',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/newuser.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'onhold',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/onhold.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'activeuser',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/activeuser.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'review',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/review.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'wms',
      this.domSanitizer.bypassSecurityTrustResourceUrl('dist/angulario/assets/icons/map.svg')
    );

    this.trelloEnabled = this.trelloService.isEnabled;

    this.userService.roles.subscribe((value) => {
      if (this.user && value) {
        this.user = new User(value, this.user);
      }
    });
    this.authenticationService.authenticatedUser.subscribe((user) => {
      if (user) {
        if (this.roles.length) {
          this.user = new User(this.userService.roles.value, user);
          this.getProjects();
        } else {
          this.userService.getRoles().subscribe((roles) => {
            this.roles = [];
            this.userService.setRoleMap(roles);
            this.user = new User(this.userService.roles.value, user);
            this.getProjects();
          });
        }
      }
    });

    this.breadcrumbService.breadcrumbs.subscribe((crumbs => {
      if (crumbs && crumbs.length) {
        this.crumbs = crumbs;
      } else {
        this.crumbs = [];
      }
    }));

    this.sessionIsValid = this.authenticationService.checkSession(60000);
  }

  getProjects() {
    this.projectService.getAll().subscribe(projects => {
      this.hasMapAccess = projects.some(p => p.completenessMap);
      projects.forEach((project) => {
        if (project.pointOfContact.id === this.user.id) {
          this.ownedProject = project;
        }
      });
      if (this.ownedProject) {
        this.startTrackingTasks();
        window.setInterval(() => {
          this.startTrackingTasks();
        }, 30000);
        this.taskService.taskChange.subscribe(() => {
          this.startTrackingTasks();
        });
      }
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  startTrackingTasks() {
    this.taskService.getTasksPendingReview().subscribe((tasks) => {
      this.tasksNeedingReview = tasks;
    });
  }


  types() {
    this.router.navigate(['type']);
  }

  pullTrelloUpdates() {
    if (this.trelloEnabled) {
      this.trelloService.pullUpdates().subscribe((tasks) => {
        console.log(tasks);
      });
    }
  }

  unimpersonate(): void {
    this.authenticationService.unImpersonate();
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  shareTempFile() {
    const configData: MatDialogConfig = {
      disableClose: true,
      autoFocus: false,
      width: '60vw',
      maxHeight: '95vh',
      data: {
        fileShare: new FileShare(this.userService.roles.value, {}),
        user: this.user
      },
      panelClass: 'dialog-with-no-padding'
    };
    this.dialog.open(ShareFileComponent, configData).afterClosed().subscribe(() => {
    });
  }

  editSiteBanner() {
    this.settings.getValue('notificationText')
      .subscribe(current => {
        const config: ConfirmPromptConfig = {
          promptText: 'Enter new site notification',
          promptBodyText: 'Enter site notification (empty to disable)',
          inputType: 'textarea',
          showTextPrompt: true,
          initialMessage: current
        };

        this.dialog.open(ConfirmPromptComponent, {
          data: config,
          width: '80vw',
        }).afterClosed()
          .subscribe(res => {
            if (res?.proceed) {
              this.settings.setValue('notificationText', res.message)
                .subscribe({
                  next: () => this.popup.open('Banner Updated', 'OK', {panelClass: 'success'}),
                  error: () => this.popup.open('Error updating', 'OK', {panelClass: 'success'}),
                });
            }
          });
      });
  }
}
