import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManagedSettingsService {

  constructor(private http: HttpClient) {
  }

  getAll(): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>('/api/managed-settings');
  }

  getValue(key: string): Observable<string> {
    return this.http.get<string>(`/api/managed-settings/${key}`)
      .pipe(catchError(err => {
        return of('error retrieving content');
      }));
  }

  setValue(key: string, value: string): Observable<void> {
    if (value === 'error retrieving content') {
      throw new Error('error saving error message as content');
    }
    value = value.replace(/\n/g, '\\n');
    value = value.replace(/"/g, '\\"');
    return this.http.post<void>('/api/managed-settings/' + key, value);
  }
}
