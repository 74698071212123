<div mat-dialog-title class="dialog-header"
     [ngClass]="{'scrolled': scrolled}">{{ (fileShare.description) ? fileShare.description : 'New Shared File' }}
</div>
<div #content mat-dialog-content class="flex-column flex-align-start-center" (scroll)="contentScrolled(content)">

  <div class="content full-width flex-column flex-align-start-center gap-2">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Description</mat-label>
      <textarea matInput
                [(ngModel)]="fileShare.description"
                matTextareaAutosize
                matAutosizeMinRows="3"
                matAutosizeMaxRows="5"
                name="releaseNotes"
                id="releaseNotes"></textarea>
    </mat-form-field>


    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Expiration Date</mat-label>
      <input matInput
             [min]="today"
             [(ngModel)]="fileShare.expirationDate"
             [matDatepicker]="expirationPicker"
      >
      <mat-datepicker-toggle matSuffix [for]="expirationPicker"></mat-datepicker-toggle>
      <mat-datepicker #expirationPicker></mat-datepicker>
    </mat-form-field>

    <portal-file-upload [file]="(fileShare.originalFilename) ? fileShare.originalFilename : file"
                        [canDelete]="false"
                        (fileAdded)="fileAdded($event)"
                        (downloadFile)="fetch(fileShare)"
                        class="full-width file-upload">
    </portal-file-upload>

    <mat-form-field *ngIf="fileShare.fileType !== undefined" class="full-width" appearance="outline">
      <mat-label>File Type</mat-label>
      <input matInput
             [(ngModel)]="fileShare.fileType">
    </mat-form-field>


    <div class="full-width flex-column flex-align-start-center gap-5">
      <form class="full-width">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Select users to share with</mat-label>
          <input matInput #userInput
                 [matAutocomplete]="userAutocomplete"
                 [formControl]="userControl">
          <mat-autocomplete #userAutocomplete="matAutocomplete" (closed)="userInput.blur()"
                            (opened)="userControl.setValue('')">
            <mat-option *ngFor="let user of filteredUsers | async"
                        [value]="concatName( user )"
                        (onSelectionChange)="selectUser($event, user)"
                        [disabled]="fileShare.recipientUsernames && fileShare.recipientUsernames.indexOf(user.username) !== -1">
              {{ displayUserInfo(user) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>

      <mat-chip-set class="full-width chip-list">
        <mat-chip class="chip" *ngFor="let username of fileShare.recipientUsernames; let i = index">
          <div class="flex-row flex-align-start-center gap-8">
            <div>{{ displayNameByUsername[username] }}</div>
            <mat-icon (click)="removeUsername(i)" class="chip-close-icon">cancel</mat-icon>
          </div>
        </mat-chip>
      </mat-chip-set>
    </div>

    <div class="full-width flex-column flex-align-start-center gap-6">
      <div class="full-width form-header">Select roles to share with</div>
      <div class="checkbox-group full-width flex-row-wrap flex-align-start-start gap-10">
        <mat-checkbox
          *ngFor="let role of editableRoles"
          color="primary"
          (change)="onRoleChange(fileShare, role)"
          [checked]="fileShare.hasRole(role)">{{ roleDescription(role) }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <iframe #downloadFrame></iframe>

  <div *ngIf="uploadInProgress" class="progress-bar">
    <mat-progress-bar class="full-width" mode="determinate" [value]="uploadPercentage"></mat-progress-bar>
  </div>
</div>

<mat-dialog-actions class="flex-row flex-align-end-center gap-10">
  <button mat-raised-button (click)="close()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!isValid" (click)="save()">
    {{ fileShare.id ? 'Save' : 'Share' }}
  </button>
</mat-dialog-actions>
