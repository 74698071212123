<div class="portal-toolbar flex-row flex-align-start-center gap-10">
  <img class="logo" alt="ORNL logo" src="assets/ornl-logo-black.png"/>
  <a routerLink="/" class="title" title="Show All Projects">Human Dynamics Portal</a>

  <!-- Check map access so that we don't just have one option at the top. -->
  <a routerLink="/project" routerLinkActive="active" *ngIf="hasMapAccess">
    <mat-icon>folder</mat-icon>
    Project Files
  </a>
  <a routerLink="/map" routerLinkActive="active" *ngIf="hasMapAccess">
    <mat-icon>map</mat-icon>
    Data Completeness Map
  </a>

  <button *ngIf="user?.roles?.length" mat-icon-button [matMenuTriggerFor]="menu"
          class="menu-button">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<mat-menu x-position="after" #menu="matMenu">
  <a *ngIf="user" [routerLink]="'/user/' + user.username" mat-menu-item>
    <mat-icon>person</mat-icon>
    {{ user.username }} profile
  </a>
  <a *ngIf="user && user.isApplicationManager()" routerLink="/user" mat-menu-item>
    <mat-icon>groups</mat-icon>
    Manage Users
  </a>
  <a *ngIf="user && user.isAdmin()" routerLink="/admin/key" mat-menu-item>
    <mat-icon>vpn_key</mat-icon>
    Manage Keys...
  </a>
  <a routerLink="/" mat-menu-item>
    <mat-icon>folder</mat-icon>
    Project Files
  </a>
  <a routerLink="/map" mat-menu-item *ngIf="hasMapAccess">
    <mat-icon>map</mat-icon>
    Data Completeness Map
  </a>
  <a mat-menu-item *ngIf="user && (user.isSponsor() || user.isPublisher() || user.isAdmin())"
     (click)="navigate('/reporting')">
    <mat-icon>table_rows</mat-icon>
    Site Metrics Report
  </a>
  <button *ngIf="trelloEnabled && user && user.isContributor()" (click)="pullTrelloUpdates()" mat-menu-item>
    Get Trello Changes
  </button>
  <!--  <button *ngIf="user && user.isAdmin()" (click)="fileTypeMigration()" mat-menu-item>File Type Migration</button>-->
  <button *ngIf="user && (user.isAdmin() || user.isPublisher())" (click)="shareTempFile()" mat-menu-item>
    <mat-icon>share</mat-icon>
    Share Temporary File
  </button>
  <button *ngIf="user && (user.isAdmin())" (click)="editSiteBanner()" mat-menu-item>
    <mat-icon>info</mat-icon>
    Change Site Banner
  </button>
  <a *ngIf="user && !user.impersonating" href="/logout" mat-menu-item>
    <mat-icon>logout</mat-icon>
    Logout
  </a>
  <button *ngIf="user && user.impersonating" (click)="unimpersonate()" mat-menu-item>
    <mat-icon>logout</mat-icon>
    Logout (unimpersonate)
  </button>
</mat-menu>

<main>
  <router-outlet></router-outlet>
</main>

<div *ngIf="!(sessionIsValid | async)" class="session-fault-mask">
  <div class="session-fault-message">
    There is a problem with the server connection.<br/>
    You may need to check your network connection or refresh the page.
  </div>
</div>
